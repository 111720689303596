<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateFrom"
              :max="filter.dateTo"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeFrom"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.dateTo"
              :min="filter.dateFrom"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.timeTo"
              locale="en"
              show-seconds
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('operator_account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.operatorAccount"
              autocomplete="off"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('transaction_account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.transactionAccount"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
          <b-button
            variant="success float-right"
            class="mr-1"
            @click="exportData"
            v-text="$t('export')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="transactions"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Amount -->
            <span
              v-if="props.column.field === 'amount'"
              v-text="Number(props.row.amount).toLocaleString()"
            />
            <!-- Column: Account -->
            <span
              v-else-if="props.column.field === 'account'"
            >
              <a
                href="#"
                class="align-middle ml-50"
                @click="filterByAccount(props.row.account)"
                v-text="props.row.account"
              />
            </span>
            <!-- Column: Transaction Type -->
            <span
              v-else-if="props.column.field === 'transaction_type'"
            >
              <span
                v-text="$t(props.row.transaction_type.split(' ').join('_').toLowerCase())"
              />
              <feather-icon
                v-if="props.row.transaction_type === 'Transfer To Child'"
                style="margin-left: 5px; color: red;"
                icon="ArrowRightIcon"
              />
              <feather-icon
                v-if="props.row.transaction_type === 'Transfer To Agent'"
                style="margin-left: 5px; color: red;"
                icon="ArrowRightIcon"
              />
              <feather-icon
                v-if="props.row.transaction_type === 'Transfer From Agent'"
                style="margin-left: 5px; color: blue;"
                icon="ArrowLeftIcon"
              />
              <feather-icon
                v-if="props.row.transaction_type === 'Transfer From Child'"
                style="margin-left: 5px; color: blue;"
                icon="ArrowLeftIcon"
              />

            </span>
            <!-- Column: Transaction Account -->
            <span
              v-else-if="props.column.field === 'transaction_account'"
            >
              <a
                href="#"
                class="align-middle ml-50"
                @click="filterByAccount(props.row.transaction_account)"
                v-text="props.row.transaction_account"
              />
            </span>
            <!-- Column: Amount Before -->
            <span
              v-else-if="props.column.field === 'amount_before'"
              v-text="Number(props.row.amount_before).toLocaleString()"
            />
            <!-- Column: Amount After -->
            <span
              v-else-if="props.column.field === 'amount_after'"
              v-text="Number(props.row.amount_after).toLocaleString()"
            />
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BOverlay,
  BCard,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BFormSelect,
  BFormInput,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import Transaction from '@/models/Transaction'

import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportTransactions',
  components: {
    BOverlay,
    BCard,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    VueGoodTable,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BFormSelect,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 50,
      columns: [
        {
          label: 'operator_account',
          field: 'operator_account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'account',
          field: 'account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'transaction_type',
          field: 'transaction_type',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'transaction_account',
          field: 'transaction_account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'time',
          field: 'time',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'currency',
          field: 'currency',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'before',
          field: 'amount_before',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'amount',
          field: 'amount',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'after',
          field: 'amount_after',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'memo',
          field: 'memo',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
      ],
      agentId: null,
      transactions: [],
      loading: true,
      filter: {
        operatorAccount: typeof this.$route.query.operatorAccount !== 'undefined' ? this.$route.query.operatorAccount : '',
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        transactionAccount: typeof this.$route.query.transactionAccount !== 'undefined' ? this.$route.query.transactionAccount : '',
        dateFrom: typeof this.$route.query.dateFrom !== 'undefined' ? this.$route.query.dateFrom : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        dateTo: typeof this.$route.query.dateTo !== 'undefined' ? this.$route.query.dateTo : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        timeFrom: typeof this.$route.query.timeFrom !== 'undefined' ? this.$route.query.timeFrom : '00:00:00',
        timeTo: typeof this.$route.query.timeTo !== 'undefined' ? this.$route.query.timeTo : '23:59:59',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async exportData(a) {
      a.preventDefault()
      try {
        await Transaction.download({ ...this.filter, agentId: this.agentId })
      } catch (e) {
        this.$alertHelper.error()
      }
    },
    async loadData() {
      this.loading = true
      const searchParams = {}
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Transaction.list(searchParams)
      this.transactions = response.data.data
      this.agentId = response.data.meta.agent.realId
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    filterByAccount(account) {
      this.filter.account = account
      this.loadData()
    },
  },
}
</script>

<style>
</style>
